<template>
  <autoform
    ref="autoform"
    :schema="schema"
    :model="model"
    :default-value="doc"
    label-position="top"
    :focused-field.sync="focusedField"
    class="h-100"
  >
    <div class="d-flex align-items-center border rounded p-2 px-4 mb-4 gap-4">
      <div class="business-name-container">
        <TruncatedText class="w-100 business-name-text">
          {{ documentBusinessName }}
        </TruncatedText>
      </div>

      <p>{{ $t('document.documentRecording.template') }}</p>
      <el-select
        :value="selectedTemplateId"
        :disabled="!model.supplierId || !model.type"
        size="small"
        class="dropdown-text-color"
        @change="onTemplateChange"
      >
        <template #prefix>
          <div v-if="isSelectedTemplateManual" class="center-select-icon">
            <PencilTrailIcon class="manual-document-icon" width="18" height="18" />
          </div>
        </template>

        <el-option
          v-for="template in templates"
          :key="template.id"
          :label="template.name"
          :value="template.id"
          :disabled="disableTemplatePick(template)"
        >
          <div class="row" :class="selectedTemplateId !== template.id ? 'text-typography-primary' : 'text-primary'">
            <div v-if="template.isManual" class="col-1 g-0" style="margin-right: 6px">
              <PencilTrailIcon width="18" height="18" />
            </div>
            <p :class="{ 'col-1': true, 'custom-disabled-class': disableTemplatePick(template) }">
              {{ template.name }}
            </p>
          </div>
        </el-option>
        <hr v-if="templates.length" class="my-1" />
        <el-option :label="$t('document.documentRecording.customSettings')" :value="null"></el-option>
      </el-select>
      <Button :disabled="!model.supplierId || !model.type" type="secondary" class="p-1" @click="openStructureParams">
        <SlidersIcon />
        <span class="d-inline-flex p-1">
          {{ $t(`document.documentRecording.structureParamsModal.${selectedTemplateId ? 'show' : 'edit'}`) }}
        </span>
      </Button>
      <div v-if="isManualDocument" class="d-flex align-items-center" :class="getMarginEndAutoClassByDirection()">
        <PencilTrailIcon class="manual-document-icon" width="18" height="18" />
        <p>
          {{ $t(`document.documentRecording.manualDocument`) }}
        </p>
      </div>
    </div>

    <div ref="tabContainer" :style="{ height: 'calc(100% - 140px)' }" @paste.prevent="handlePaste">
      <h3 class="pt-1 pb-5">{{ $t('document.documentRecording.generalSettings') }}</h3>
      <div class="d-flex gap-4">
        <autoinput key="supplierId" field-key="supplierId" class="flex-grow-1" />
        <autoinput key="type" field-key="type">
          <el-form-item slot-scope="{ fieldKey, fieldDefinition, rule }" :prop="fieldKey" :rules="rule">
            <el-select
              v-model="model.type"
              clearable
              :placeholder="fieldDefinition.label"
              :disabled="editMode"
              class="w-100"
              @change="onTypeChange"
            >
              <el-option
                v-for="value in fieldDefinition.type[0].allowedValues"
                :key="value"
                :value="value"
                :label="$t(`document.exports.schema.type.fullName.${value}`)"
              />
            </el-select>
          </el-form-item>
        </autoinput>

        <autoinput v-if="hasIssueDate" key="issueDate" field-key="issueDate">
          <el-form-item slot-scope="scope" :prop="scope.fieldKey" :rules="scope.rule">
            <input-date
              v-model="model.issueDate"
              format="dd/MM/yyyy"
              :placeholder="scope.fieldDefinition.label"
              class="w-100"
            />
          </el-form-item>
        </autoinput>
        <autoinput v-if="hasDeliveryDate" key="deliveryDate" field-key="deliveryDate">
          <el-form-item slot-scope="scope" :prop="scope.fieldKey" :rules="scope.rule">
            <input-date
              v-model="model.deliveryDate"
              format="dd/MM/yyyy"
              :placeholder="$t('document.exports.schema.fields.deliveryDate')"
              class="w-100"
            />
          </el-form-item>
        </autoinput>
        <autoinput v-if="hasDocumentNumber" key="documentNumber" field-key="documentNumber" class="flex-grow-1" />
        <autoinput v-if="hasOrderReference" key="orderReference" field-key="orderReference" class="flex-grow-1" />
        <autoinput v-if="hasAllocationNumber" key="allocationNumber" field-key="allocationNumber" class="flex-grow-1" />
      </div>

      <template v-if="model.supplierId && model.type">
        <div :style="{ height: 'calc(100% - 160px)' }" class="overflow-auto">
          <div class="row">
            <div class="col-4">
              <autoinput
                v-if="model.structureParams.referencesFromDate"
                key="referencesFromDate"
                field-key="referencesFromDate"
              >
                <el-form-item slot-scope="scope" :prop="scope.fieldKey" :rules="scope.rule">
                  <input-date
                    v-model="model.referencesFromDate"
                    format="dd/MM/yyyy"
                    :placeholder="scope.fieldDefinition.label"
                    class="w-100"
                  />
                </el-form-item>
              </autoinput>
            </div>
            <div class="col-4">
              <autoinput
                v-if="model.structureParams.referencesToDate"
                key="referencesToDate"
                field-key="referencesToDate"
              >
                <el-form-item slot-scope="scope" :prop="scope.fieldKey" :rules="scope.rule">
                  <input-date
                    v-model="model.referencesToDate"
                    format="dd/MM/yyyy"
                    :placeholder="scope.fieldDefinition.label"
                    class="w-100"
                  />
                </el-form-item>
              </autoinput>
            </div>
            <div class="col-4">
              <autoinput
                v-if="model.structureParams.openingBalance"
                key="openingBalance"
                field-key="openingBalance"
                :component="$options.components.AutoinputMoney"
              />
            </div>
          </div>
          <autoinput v-if="model.structureParams.items" key="items" field-key="items" />
          <autoinput v-if="model.structureParams.references" key="references" field-key="references" />
          <autoinput v-if="hasGeneralChargesParams" key="generalCharges" field-key="generalCharges" />

          <table class="float-start" style="max-width: 400px">
            <thead>
              <th>
                <h3 v-if="hasSummary" class="mb-4">{{ $t('document.documentRecording.summary') }}</h3>
              </th>
            </thead>
            <tbody>
              <tr v-if="model.structureParams.paymentDueDate">
                <td>
                  <p class="pb-4 ms-2">
                    {{ $t('document.exports.schema.fields.paymentDueDate') }}
                  </p>
                </td>
                <td>
                  <autoinput key="paymentDueDate" field-key="paymentDueDate" class="mb-4" />
                </td>
              </tr>
              <tr v-if="model.structureParams.discountRate">
                <td>
                  <p class="pb-4 ms-2">
                    {{ $t('document.exports.schema.fields.discountRate') }}
                  </p>
                </td>
                <td>
                  <autoinput key="discountRate" field-key="discountRate" class="mb-4" />
                </td>
              </tr>
              <tr v-if="model.structureParams.discountAmount">
                <td>
                  <p class="pb-4 ms-2">
                    {{ $t('document.exports.schema.fields.discountAmount') }}
                  </p>
                </td>
                <td>
                  <autoinput
                    key="discountAmount"
                    field-key="discountAmount"
                    :component="$options.components.AutoinputMoney"
                    class="mb-4 direction"
                  />
                </td>
              </tr>
              <tr v-if="model.structureParams.rounding">
                <td>
                  <p class="pb-4 ms-2">
                    {{ $t('document.exports.schema.fields.rounding') }}
                  </p>
                </td>
                <td>
                  <autoinput
                    key="rounding"
                    field-key="rounding"
                    :component="$options.components.AutoinputMoney"
                    class="mb-4 direction"
                  />
                </td>
              </tr>
              <tr v-if="model.structureParams.netAmount">
                <td>
                  <p class="pb-4 ms-2">
                    {{ $t('document.exports.schema.fields.netAmount') }}
                  </p>
                </td>
                <td>
                  <autoinput
                    key="netAmount"
                    field-key="netAmount"
                    :component="$options.components.AutoinputMoney"
                    class="mb-4 direction"
                  />
                </td>
              </tr>
              <tr v-if="model.structureParams.taxableAmount">
                <td>
                  <p class="pb-4 ms-2">
                    {{ $t('document.exports.schema.fields.taxableAmount') }}
                  </p>
                </td>
                <td>
                  <autoinput
                    key="taxableAmount"
                    field-key="taxableAmount"
                    :component="$options.components.AutoinputMoney"
                    class="mb-4 direction"
                  />
                </td>
              </tr>
              <tr v-if="model.structureParams.nonTaxableAmount">
                <td>
                  <p class="pb-4 ms-2">
                    {{ $t('document.exports.schema.fields.nonTaxableAmount') }}
                  </p>
                </td>
                <td>
                  <autoinput
                    key="nonTaxableAmount"
                    field-key="nonTaxableAmount"
                    :component="$options.components.AutoinputMoney"
                    class="mb-4 direction"
                  />
                </td>
              </tr>
              <tr v-if="model.structureParams.taxRate">
                <td>
                  <p class="pb-4 ms-2">
                    {{ $t('document.exports.schema.fields.taxRate') }}
                  </p>
                </td>
                <td>
                  <div :class="model.structureParams.taxAmount ? 'd-flex' : ''">
                    <autoinput
                      key="taxRate"
                      field-key="taxRate"
                      class="mb-4"
                      :style="model.structureParams.taxAmount ? 'max-width: 70px' : ''"
                    />
                    <div v-if="model.structureParams.taxAmount" class="d-flex">
                      <p class="pt-0 mb-4 ms-2 me-2" style="self-align: center">
                        {{ $t('document.exports.schema.fields.taxAmount') }}
                      </p>
                      <autoinput
                        key="taxAmount"
                        field-key="taxAmount"
                        :component="$options.components.AutoinputMoney"
                        class="mb-4 direction"
                        style="max-width: 130px"
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-else-if="model.structureParams.taxAmount">
                <td>
                  <p class="pb-4 ms-2">
                    {{ $t('document.exports.schema.fields.taxAmount') }}
                  </p>
                </td>
                <td>
                  <autoinput
                    key="taxAmount"
                    field-key="taxAmount"
                    :component="$options.components.AutoinputMoney"
                    class="mb-4 direction"
                  />
                </td>
              </tr>
              <tr v-if="model.structureParams.totalAmount">
                <td>
                  <p class="pb-4 ms-2">
                    {{ $t('document.exports.schema.fields.totalAmount') }}
                  </p>
                </td>
                <td>
                  <autoinput
                    key="totalAmount"
                    field-key="totalAmount"
                    :component="$options.components.AutoinputMoney"
                    class="mb-4 direction"
                  />
                </td>
              </tr>
              <tr v-if="model.structureParams.amountDue">
                <td>
                  <p class="pb-4 ms-2">
                    {{ $t('document.exports.schema.fields.amountDue') }}
                  </p>
                </td>
                <td>
                  <autoinput
                    key="amountDue"
                    field-key="amountDue"
                    :component="$options.components.AutoinputMoney"
                    class="mb-4 direction"
                  />
                </td>
              </tr>
              <template v-if="model.structureParams.paidAmount || model.structureParams.paymentMethod">
                <tr v-if="model.structureParams.paidAmount">
                  <td>
                    <p class="pb-4 ms-2">
                      {{ $t('document.exports.schema.fields.paidAmount') }}
                    </p>
                  </td>
                  <td>
                    <autoinput
                      key="paidAmount"
                      field-key="paidAmount"
                      :component="$options.components.AutoinputMoney"
                      class="mb-4 direction"
                    />
                  </td>
                </tr>
                <tr v-if="model.structureParams.paymentDate">
                  <td>
                    <p class="pb-4 ms-2">
                      {{ $t('document.exports.schema.fields.paymentDate') }}
                    </p>
                  </td>
                  <td>
                    <autoinput key="paymentDate" field-key="paymentDate" class="mb-4" />
                  </td>
                </tr>
                <tr v-if="model.structureParams.paymentMethod && model.paymentMethod">
                  <td>
                    <p class="pb-4 ms-2">
                      {{ $t('document.exports.schema.fields.paymentMethod') }}
                    </p>
                  </td>
                  <td>
                    <autoinput key="paymentMethod.type" field-key="paymentMethod.type" class="mb-4" />
                  </td>
                </tr>
                <template v-if="model.paymentMethod">
                  <template v-if="model.paymentMethod.type === PAYMENT_METHOD_TYPE.CREDIT_CARD">
                    <tr>
                      <td>
                        <p class="pb-4 ms-2">
                          {{ $t('payment.paymentMethodModal.labels.cardType') }}
                        </p>
                      </td>
                      <td>
                        <autoinput
                          key="paymentMethod.creditCard.type"
                          field-key="paymentMethod.creditCard.type"
                          class="mb-4"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="pb-4 ms-2">
                          {{ $t('payment.paymentMethodModal.labels.lastFourDigits') }}
                        </p>
                      </td>
                      <td>
                        <autoinput
                          key="paymentMethod.creditCard.number"
                          field-key="paymentMethod.creditCard.number"
                          class="mb-4"
                        />
                      </td>
                    </tr>
                  </template>
                  <template v-if="model.paymentMethod.type === PAYMENT_METHOD_TYPE.CHEQUE">
                    <tr>
                      <td>
                        <p class="pb-4 ms-2">
                          {{ $t('payment.paymentMethodModal.labels.chequeNumber') }}
                        </p>
                      </td>
                      <td>
                        <autoinput
                          key="paymentMethod.cheque.number"
                          field-key="paymentMethod.cheque.number"
                          class="mb-4"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="pb-4 ms-2">
                          {{ $t('payment.paymentMethodModal.labels.dueDate') }}
                        </p>
                      </td>
                      <td>
                        <autoinput
                          key="paymentMethod.cheque.dueDate"
                          field-key="paymentMethod.cheque.dueDate"
                          class="mb-4"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="pb-4 ms-2">
                          {{ $t('payment.paymentMethodModal.labels.bank') }}
                        </p>
                      </td>
                      <td>
                        <el-select
                          v-model="model.paymentMethod.cheque.bank"
                          filterable
                          clearable
                          :placeholder="$t('document.documentRecording.params.paymentMethod.bank')"
                          class="w-100 mb-2"
                        >
                          <el-option
                            v-for="(bankName, bankNumber) in BANK_NUMBER_TO_BANK_NAME"
                            :key="bankNumber"
                            :value="bankNumber"
                            :label="`${bankNumber} - ${bankName}`"
                          />
                        </el-select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="pb-4 ms-2">
                          {{ $t('payment.paymentMethodModal.labels.branch') }}
                        </p>
                      </td>
                      <td>
                        <autoinput
                          key="paymentMethod.cheque.branch"
                          field-key="paymentMethod.cheque.branch"
                          class="mb-4"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="pb-4 ms-2">
                          {{ $t('payment.paymentMethodModal.labels.account') }}
                        </p>
                      </td>
                      <td>
                        <autoinput
                          key="paymentMethod.cheque.account"
                          field-key="paymentMethod.cheque.account"
                          class="mb-4"
                        />
                      </td>
                    </tr>
                  </template>
                  <template v-if="model.paymentMethod.type === PAYMENT_METHOD_TYPE.BANK_TRANSFER">
                    <tr>
                      <td>
                        <p class="pb-4 ms-2">
                          {{ $t('payment.paymentMethodModal.labels.bank') }}
                        </p>
                      </td>
                      <td>
                        <el-select
                          v-model="model.paymentMethod.bankTransfer.bank"
                          clearable
                          filterable
                          :placeholder="$t('document.documentRecording.params.paymentMethod.bank')"
                          class="w-100 mb-2"
                        >
                          <el-option
                            v-for="(bankName, bankNumber) in BANK_NUMBER_TO_BANK_NAME"
                            :key="bankNumber"
                            :value="bankNumber"
                            :label="`${bankNumber} - ${bankName}`"
                          />
                        </el-select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="pb-4 ms-2">
                          {{ $t('payment.paymentMethodModal.labels.branch') }}
                        </p>
                      </td>
                      <td>
                        <autoinput
                          key="paymentMethod.bankTransfer.branch"
                          field-key="paymentMethod.bankTransfer.branch"
                          class="mb-4"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="pb-4 ms-2">
                          {{ $t('payment.paymentMethodModal.labels.account') }}
                        </p>
                      </td>
                      <td>
                        <autoinput
                          key="paymentMethod.bankTransfer.account"
                          field-key="paymentMethod.bankTransfer.account"
                          class="mb-4"
                        />
                      </td>
                    </tr> </template
                ></template>
              </template>
            </tbody>
          </table>
        </div>
      </template>
    </div>

    <el-form-item
      slot="documentNumber"
      slot-scope="scope"
      prop="documentNumber"
      :rules="scope.rule"
      :error="getDocumentNumberValidationError()"
    >
      <template slot="error">
        <small class="el-form-item__error">
          <DocumentAlreadyExist
            :document-type="model.type"
            :is-document-number-already-exists="!!getDocumentNumberValidationError()"
            :is-replicate-of="!!model.replicateOf"
            @on-show-document="viewExistingDocumentByDocumentNumber = true"
            @on-toggle-replicate-of="onReplicateOfToggle"
          />
        </small>
      </template>
      <el-input
        v-model="model.documentNumber"
        clearable
        :placeholder="$t('document.exports.schema.fields.documentNumberSpField')"
        class="w-100"
        :disabled="model.replicateOf ? true : false"
        @input="handleDocumentNumberChange"
      />
    </el-form-item>
    <el-form-item slot="allocationNumber" slot-scope="scope" prop="allocationNumber" :rules="[scope.rule]">
      <el-input
        v-model="model.allocationNumber"
        clearable
        :placeholder="$t('document.exports.schema.fields.allocationNumber')"
        class="w-100"
      />
    </el-form-item>
    <el-form-item
      slot="orderReference"
      slot-scope="scope"
      prop="orderReference"
      :rules="scope.rule"
      :error="getOrderReferenceValidationError()"
    >
      <template slot="error">
        <small class="el-form-item__error">
          {{ $t('document.validation.documentNumber.documentMightAlreadyExist') }}
          <u @click="viewExistingDocumentByOrderReference = true">
            {{ $t('document.validation.documentNumber.displayDocument') }}
          </u>
        </small>
      </template>
      <el-input
        v-model="model.orderReference"
        clearable
        :placeholder="$t('document.exports.schema.fields.orderReference')"
        class="w-100"
      />
    </el-form-item>
    <el-form-item slot="supplierId" slot-scope="scope" prop="supplierId" :rules="[scope.rule, supplierRule]">
      <el-select
        v-model="model.supplierId"
        clearable
        filterable
        placeholder="Supplier"
        :disabled="editMode"
        class="w-100"
        @change="onSupplierChange"
      >
        <el-option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id" :label="supplier.name" />
      </el-select>
    </el-form-item>

    <form-charges
      slot="generalCharges"
      slot-scope="scope"
      :general-charges-structure-params="model.structureParams.generalCharges"
      v-bind="scope"
    />

    <el-form-item
      slot="generalCharges.$.name"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-input :value="value" class="w-100" @input="input" />
    </el-form-item>

    <el-form-item
      slot="generalCharges.$.amount"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0 direction"
    >
      <input-money :show-icon="false" :value="value" class="w-100" @input="input" />
    </el-form-item>

    <form-items
      slot="items"
      slot-scope="scope"
      v-bind="scope"
      :model="model"
      :items-params="model.structureParams.items"
      edit-mode
      clearable
      class="mb-7"
      @reset-items="resetItems"
      @item-created="refetchProducts"
    />

    <div
      slot="items.$"
      slot-scope="{ fieldKey }"
      class="rounded py-2"
      :class="{ 'border border-danger': isRowError(fieldKey.split('.').pop()) }"
    >
      <el-popover v-if="isRowError(fieldKey.split('.').pop())" placement="top-start" trigger="hover">
        <p slot="reference">{{ Number(fieldKey.split('.').pop()) + 1 }}</p>
        <div v-if="!model.items[fieldKey.split('.').pop()].productId">
          <div class="d-flex align-items-center gap-2">
            <NoticeIcon class="text-danger" width="18" height="18" />
            <p class="fw-bold">{{ $t('document.documentRecording.unidentifiedProduct') }}</p>
          </div>
        </div>
        <div v-if="getValidationRowErrors(fieldKey.split('.').pop())">
          <div class="d-flex align-items-center gap-2">
            <NoticeIcon class="text-danger" width="18" height="18" />
            <p class="fw-bold">{{ $t('document.documentRecording.validation.errorBox.title') }}</p>
          </div>
          <ol>
            <li v-for="(error, i) in getValidationRowErrors(fieldKey.split('.').pop())" :key="i">
              <p v-if="error.fields.length">
                {{
                  $t('document.documentRecording.validation.errorBox.unbalanced', {
                    fields: error.fields
                      .slice(0, -1)
                      .map((field) => $t(`document.exports.schema.itemsFields.${field}`))
                      .join(', '),
                    lastField: $t(`document.exports.schema.itemsFields.${error.fields.slice(-1)[0]}`),
                  })
                }}
              </p>
            </li>
          </ol>
        </div>
      </el-popover>
      <p v-else>{{ Number(fieldKey.split('.').pop()) + 1 }}</p>
    </div>
    <el-form-item
      slot="items.$.reference"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-input :value="value" class="w-100" @input="input" />
    </el-form-item>

    <el-form-item
      slot="items.$.sku"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-input
        :value="value"
        clearable
        class="w-100 direction-ltr"
        @input="input"
        @blur="updateItemFields(fieldKey)"
      />
    </el-form-item>

    <el-form-item
      slot="items.$.gtin"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-input :value="value" class="w-100" @input="input" />
    </el-form-item>

    <el-form-item
      slot="items.$.name"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-autocomplete
        :value="value"
        :fetch-suggestions="fetchNameSuggestions"
        clearable
        class="w-100"
        popper-class="item-name-values-popover"
        @input="input"
        @blur="updateItemFields(fieldKey)"
        @select="updateItemFields(fieldKey)"
      />
    </el-form-item>

    <el-form-item
      slot="items.$.quantity"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-input :value="value" type="number" class="w-100" @input="input" />
    </el-form-item>

    <el-form-item
      slot="items.$.packageQuantity"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-input :value="value" type="number" class="w-100" @input="input" />
    </el-form-item>

    <el-form-item
      slot="items.$.quantityInPackage"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-input :value="value" type="number" class="w-100" @input="input" />
    </el-form-item>

    <el-form-item
      slot="items.$.price"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <div class="d-flex">
        <input-money :show-icon="false" :value="value" class="w-100" @input="input" />
        <el-popover v-if="recommendations[fieldKey] && !recommendations[fieldKey].chosen" placement="right">
          <el-button slot="reference" type="primary" plain icon="el-icon-view" />
          Replace with {{ recommendations[fieldKey].recommendedValue | money }}?
          <el-button
            size="mini"
            type="success"
            plain
            icon="el-icon-check"
            class="ms-4"
            @click="insertRecommendation(fieldKey)"
          />
          <el-button
            size="mini"
            type="danger"
            plain
            icon="el-icon-close"
            @click="recommendations[fieldKey].chosen = 'original'"
          />
        </el-popover>
      </div>
    </el-form-item>

    <el-form-item
      slot="items.$.totalAmount"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <div class="d-flex">
        <input-money :show-icon="false" :value="value" class="w-100" @input="input" />
        <el-popover v-if="recommendations[fieldKey] && !recommendations[fieldKey].chosen" placement="right">
          <el-button slot="reference" type="primary" plain icon="el-icon-view" />
          Replace with {{ recommendations[fieldKey].recommendedValue | money }}?
          <el-button
            size="mini"
            type="success"
            plain
            icon="el-icon-check"
            class="ms-4"
            @click="insertRecommendation(fieldKey)"
          />
          <el-button
            size="mini"
            type="danger"
            plain
            icon="el-icon-close"
            @click="recommendations[fieldKey].chosen = 'original'"
          />
        </el-popover>
      </div>
    </el-form-item>

    <form-notes
      slot="references"
      slot-scope="scope"
      v-bind="scope"
      :structure-params="model.structureParams"
      clearable
      @reset-references="resetReferences"
    />

    <el-form-item
      slot="references.$.documentNumber"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-input :value="value" class="w-100" @input="input" />
    </el-form-item>
    <el-form-item
      slot="references.$.orderReference"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-input :value="value" class="w-100" @input="input" />
    </el-form-item>
    <el-form-item
      slot="references.$.netAmount"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <input-money
        :show-icon="false"
        :value="value"
        :disabled="!model.structureParams.references.netAmount"
        class="w-100 direction"
        @input="input"
      />
    </el-form-item>

    <el-form-item
      slot="references.$.totalAmount"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <input-money
        :show-icon="false"
        :value="value"
        :disabled="!model.structureParams.references.totalAmount"
        class="w-100 direction"
        @input="input"
      />
    </el-form-item>

    <el-form-item
      slot="references.$.debitAmount"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <input-money :show-icon="false" :value="value" class="w-100 direction" @input="input" />
    </el-form-item>

    <el-form-item
      slot="references.$.creditAmount"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <input-money :show-icon="false" :value="value" class="w-100 direction" @input="input" />
    </el-form-item>

    <el-form-item
      slot="references.$.balance"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <input-money :show-icon="false" :value="value" class="w-100 direction" @input="input" />
    </el-form-item>

    <form-items
      slot="references.$.items"
      slot-scope="scope"
      v-bind="scope"
      :model="model"
      :items-params="model.structureParams.references.items"
    />

    <div
      slot="references.$.items.$"
      slot-scope="scope"
      v-bind="scope"
      :model="model"
      :items-params="model.structureParams.references.items"
    >
      <p slot="reference">{{ Number(scope.fieldKey.split('.').pop()) + 1 }}</p>
    </div>

    <el-form-item
      slot="references.$.items.$.sku"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-input :value="value" clearable class="w-100" @input="input" @blur="updateItemFields(fieldKey)" />
    </el-form-item>

    <el-form-item
      slot="references.$.items.$.name"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-select :value="value" clearable filterable class="w-100" @input="input" @change="updateItemFields(fieldKey)">
        <el-option v-for="item in supplierItems" :key="item.id" :value="item.name" />
      </el-select>
    </el-form-item>

    <el-form-item
      slot="references.$.items.$.quantity"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-input :value="value" type="number" class="w-100 direction" @input="input" />
    </el-form-item>

    <el-form-item
      slot="references.$.items.$.packageQuantity"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-input :value="value" type="number" class="w-100" @input="input" />
    </el-form-item>

    <el-form-item
      slot="references.$.items.$.quantityInPackage"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <el-input :value="value" type="number" class="w-100" @input="input" />
    </el-form-item>

    <el-form-item
      slot="references.$.items.$.price"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <input-money :show-icon="false" :value="value" class="w-100" @input="input" />
    </el-form-item>

    <el-form-item
      slot="references.$.items.$.totalAmount"
      slot-scope="{ fieldKey, value, input, rule }"
      :prop="fieldKey"
      :rules="rule"
      :show-message="false"
      class="mb-0"
    >
      <input-money :show-icon="false" :value="value" class="w-100" @input="input" />
    </el-form-item>

    <StructureParamsDrawer
      v-if="paramDialogVisible"
      :visible.sync="paramDialogVisible"
      :current-template="currentTemplate"
      :supplier="selectedSupplier"
      @updateDocument="updateParamsFromDrawer"
      @updateTemplate="updateTemplate"
      @save-new-template="saveNewTemplate"
    />

    <DocumentModal
      v-if="viewExistingDocumentByDocumentNumber"
      visible
      :document-id="replicateDocumentId"
      @close="viewExistingDocumentByDocumentNumber = false"
    />
    <DocumentModal
      v-if="viewExistingDocumentByOrderReference"
      visible
      :document-id="existingDocumentIdByOrderReference"
      @close="viewExistingDocumentByOrderReference = false"
    />

    <TemplateNameModal
      v-if="nameModalOpen"
      :supplier="selectedSupplier"
      :document-type="model.type"
      @submit="createNewTemplate"
      @close="nameModalOpen = false"
    />
  </autoform>
</template>

<script type="text/javascript">
import { isNil, clone, isEmpty, omit, flatten } from 'ramda';
import SimpleSchema from 'simpl-schema';
import { convertValue } from '@clarityo/xml2doc';
import { computed, ref, getCurrentInstance } from 'vue';

import { useProductsNew } from '@/modules/product/compositions/products';
import { BaseDocumentSchema, getDefaultParams } from '@/imports/api/schemas/documents';
import DocumentSchemas from '@/imports/api/schemas/documents';
import { getModel } from '@/modules/templates/components/autoform/autoform_utils';
import Autoform from '@/modules/templates/components/autoform/autoform';
import Autoinput from '@/modules/templates/components/autoform/autoinput';
import AutoinputMoney from './autoinput_money';
import InputDate from '@/modules/core/components/forms/InputDate';
import i18n from '@/imports/startup/client/i18n';
import { DocumentModal } from '@/modules/documentModal';
import { PAYMENT_METHOD_TYPE, CREDIT_CARD_TYPE } from '@/modules/payment/types';
import { constants as termConstants } from '@/modules/purchase-management';
import { Button, TruncatedText } from '@/modules/core';
import { SlidersIcon, NoticeIcon, PencilTrailIcon } from '@/assets/icons';
import { TemplateNameModal, useTemplates, useTemplateCreate, useTemplateUpdate } from '@/modules/automation';
import FormItems from './document-form/document_form_items';
import FormCharges from './document-form/document_form_charges';
import FormNotes from './document-form/document_form_notes';
import { StructureParamsDrawer } from '@/modules/document/components/structureParameters';
import { useDocumentsByOrderReference, DOCUMENTS_BY_DOCUMENT_NUMBER_QUERY } from '../compositions/documents';
import InputMoney from './InputMoney';
import { useSuppliersNew } from '../../business/compositions';
import { useCurrency } from '@/modules/document/compositions';
import DocumentAlreadyExist from './DocumentAlreadyExist';
import { getBusinessById } from '@/modules/document/compositions/useBusinessById';

const { BANK_NUMBER_TO_BANK_NAME } = termConstants;

function convertValueFromField(value, fieldType, fieldKey) {
  if (fieldType === Number && fieldKey !== 'price') return convertValue(value, 'Number');
  if (fieldType === SimpleSchema.Integer || fieldKey === 'price') return convertValue(value, 'Money');
  if (fieldType === Date) return convertValue(value, 'Date');
  return value;
}

const mapValidationErrors = (currency, validationError) => {
  const { type, path, context } = validationError;
  const error = {
    name: path.join('.'),
    type,
  };
  switch (type) {
    case 'custom.unbalanced':
      return {
        ...error,
        expected: isNil(context.expectedValue)
          ? 'unknown'
          : context.expectedValue.toLocaleString(i18n.locale, currency),
      };
    default:
      return error;
  }
};

const getMainFieldFromRelation = (fields) => {
  const fieldsInPriority = ['totalAmount', 'totalDiscount', 'netPrice', 'quantity'];
  for (const field of fieldsInPriority) {
    if (fields.includes(field)) return field;
  }
  return fields[fields.lengh - 1];
};
const transformItemValidationErrors = (validationError) => {
  return validationError.context.invalidRelations.map(({ fields }) => {
    const field = getMainFieldFromRelation(fields);
    return {
      name: `${validationError.path.join('.')}.${field}`,
      type: validationError.type,
      expected:
        validationError.context.possibleCorrections.find((c) => Object.keys(c).includes(field))?.[field] ?? 'unknown',
    };
  });
};

const transformDocumentValidationErrors = (validationError) => {
  return flatten(
    validationError.context.invalidRelations.map(({ fields }, index) =>
      fields.map((field) => ({
        name: field,
        type: 'custom.documentUnbalanced',
        index: index + 1,
      }))
    )
  );
};

const getDefaultPaymentMethodFields = () => ({
  bankTransfer: {
    bank: undefined,
    branch: undefined,
    account: undefined,
  },
  creditCard: {
    type: undefined,
    number: undefined,
  },
  cheque: {
    bank: undefined,
    branch: undefined,
    number: undefined,
    account: undefined,
    dueDate: undefined,
  },
});

const omitTypenameAndNull = (key, value) => (key === '__typename' || value === null ? undefined : value);
const cleanNullsAndTypename = (data) => JSON.parse(JSON.stringify(data), omitTypenameAndNull);

export default {
  components: {
    FormItems,
    Autoform,
    Autoinput,
    // eslint-disable-next-line vue/no-unused-components
    AutoinputMoney,
    InputDate,
    FormCharges,
    FormNotes,
    StructureParamsDrawer,
    DocumentModal,
    Button,
    TruncatedText,
    InputMoney,
    SlidersIcon,
    NoticeIcon,
    PencilTrailIcon,
    TemplateNameModal,
    DocumentAlreadyExist,
  },
  props: {
    doc: { type: Object, default: null },
    editMode: {
      type: Boolean,
      default: false,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const businessId = computed(() => (!props.doc ? null : props.doc.businessId));

    const model = ref({});
    const { templates, refetch: refetchTemplates } = useTemplates(
      () => ({
        supplierId: model.value.supplierId,
        documentType: model.value.type,
        signedUrl: false,
        isArchived: false,
      }),
      () => ({ enabled: Boolean(model.value.supplierId) && Boolean(model.value.type) })
    );
    const { suppliers } = useSuppliersNew();
    const { templateCreate, onDone: templateCreated } = useTemplateCreate();

    const { documents: documentsWithOrderReferences } = useDocumentsByOrderReference(
      computed(() => ({
        businessId: businessId.value,
        supplierId: model.value.supplierId,
        orderReference: model.value.orderReference,
        types: [model.value.type],
      }))
    );

    const existingDocumentIdByOrderReference = computed(() => {
      const doc = documentsWithOrderReferences.value.find((d) => d.orderReference === model.value.orderReference);
      return doc?.id ?? null;
    });

    const { business: documentBusiness } = getBusinessById(businessId);
    const documentBusinessName = computed(() => documentBusiness?.value?.name);

    templateCreated(() => {
      root.$message.success(root.$t('document.documentRecording.templateCreatedSuccess'));
    });
    const { products: supplierItems, refetch: refetchProducts } = useProductsNew(
      computed(() => model.value.supplierId)
    );

    const resetWidthForAllItemNamePopovers = () => {
      const itemNameValuesPopovers = document.querySelectorAll('.item-name-values-popover');
      itemNameValuesPopovers.forEach((popover) => {
        if (!popover.__observerAttached) {
          popover.style.width = 'auto';

          const observer = new MutationObserver(() => {
            popover.style.width = 'auto';
          });

          observer.observe(popover, { attributes: true, attributeFilter: ['style'] });
          popover.__observerAttached = true;
        }
      });
    };

    const { templateUpdate } = useTemplateUpdate();

    const { currencyLocaleStyle } = useCurrency();

    return {
      inValidationProcess: ref(false),
      businessId,
      suppliers: computed(() => [...suppliers.value].sort((a, b) => a.name.localeCompare(b.name))),
      supplierItems: computed(() => [...supplierItems.value].sort((a, b) => a.name.localeCompare(b.name))),
      templates,
      refetchTemplates,
      refetchProducts,
      templateCreate,
      templateCreated,
      resetWidthForAllItemNamePopovers,
      model,
      init: ref(true),
      removeBtn: ref(false),
      changeDirty: ref(false),
      focusedField: ref(null),
      lastFocusedField: ref(null),
      paramDialogVisible: ref(false),
      recommendations: {},
      existingDocumentIdByDocumentNumber: ref(null),
      existingDocumentIdByOrderReference,
      viewExistingDocumentByDocumentNumber: ref(false),
      viewExistingDocumentByOrderReference: ref(false),
      PAYMENT_METHOD_TYPE,
      CREDIT_CARD_TYPE,
      BANK_NUMBER_TO_BANK_NAME,
      nameModalOpen: ref(false),
      newTemplate: ref(null),
      customSettingsSelected: ref(false),
      templateUpdate,
      validationErrors: ref([]),
      currencyLocaleStyle,
      documentBusinessName,
    };
  },
  computed: {
    schema() {
      const type = this.init ? this.doc?.type : this.model.type;
      return type ? DocumentSchemas[type] : BaseDocumentSchema;
    },
    hasDocumentNumber() {
      return this.model?.structureParams?.documentNumber;
    },
    hasAllocationNumber() {
      return this.model?.structureParams?.allocationNumber;
    },
    hasOrderReference() {
      return this.model?.structureParams?.orderReference;
    },
    hasIssueDate() {
      return this.model?.structureParams?.issueDate;
    },
    hasDeliveryDate() {
      return this.model?.structureParams?.deliveryDate;
    },
    hasItems() {
      return Boolean(this.model.structureParams.items);
    },
    currentLocale() {
      return this.model.locale ?? this.doc.locale;
    },
    hasSummary() {
      const summaryFields = [
        'paymentDueDate',
        'discountRate',
        'discountAmount',
        'rounding',
        'netAmount',
        'taxableAmount',
        'nonTaxableAmount',
        'taxRate',
        'totalAmount',
        'amountDue',
        'paidAmount',
        'paymentMethod',
      ];
      return summaryFields.some((field) => this.model.structureParams[field]);
    },

    hasGeneralChargesParams() {
      return this.model.structureParams.generalCharges?.name || this.model.structureParams.generalCharges?.amount;
    },
    selectedSupplierName() {
      const supplier = this.suppliers?.find((s) => s.id === this.model.supplierId);
      return supplier && supplier.name;
    },
    selectedSupplier() {
      return this.suppliers?.find((s) => s.id === this.model.supplierId);
    },
    supplierRule() {
      return { type: 'enum', enum: this.suppliers?.map(({ id }) => id), message: 'invalid supplier' };
    },
    cleanedTemplates() {
      return this.templates.map((template) => cleanNullsAndTypename(template));
    },
    selectedTemplateId() {
      return this.cleanedTemplates.find((template) => template.id === this.model.templateId)?.id ?? null;
    },
    isSelectedTemplateManual() {
      return this.cleanedTemplates.find((template) => template.id === this.model.templateId)?.isManual ?? false;
    },
    isManualDocument() {
      return this.doc.isManual ?? false;
    },
    currentTemplate() {
      if (this.selectedTemplateId && !this.customSettingsSelected) {
        return this.cleanedTemplates.find((template) => template.id === this.selectedTemplateId);
      } else {
        return {
          documentType: this.model.type,
          structureParams: this.model.structureParams,
        };
      }
    },
    rowErrors() {
      return this.validationErrors.filter(({ path }) => path.length === 2);
    },
    replicateDocumentId() {
      return this.existingDocumentIdByDocumentNumber || this.model.replicateOf;
    },
  },
  watch: {
    'model.structureParams.paymentMethod'(hasPaymentMethod) {
      if (hasPaymentMethod && !this.model.paymentMethod)
        this.$set(this.model, 'paymentMethod', {
          type: null,
          ...getDefaultPaymentMethodFields(),
        });

      if (this.model.paymentMethod) {
        const paymentType = this.model.paymentMethod?.type;
        if (paymentType && !this.model.paymentMethod[paymentType]) {
          const defaults = getDefaultPaymentMethodFields();
          this.model.paymentMethod[paymentType] = defaults[paymentType];
        }
      }

      if (!hasPaymentMethod) this.$delete(this.model, 'paymentMethod');
    },
    //TODO - add handle orderReference change
    'model.documentNumber'() {
      this.handleDocumentNumberChange(this.model.documentNumber);
    },
    'model.type'() {
      this.init = false;
    },
    'model.netAmount'(newValue, oldValue) {
      if (this.model.taxableAmount === oldValue) this.model.taxableAmount = newValue;
    },
    'model.items.length'() {
      this.$nextTick(() => {
        this.resetWidthForAllItemNamePopovers();
      });
    },
    model: {
      handler() {
        this.changeDirty = true;
      },
      deep: true,
    },
    changeDirty(value) {
      if (value)
        setTimeout(() => {
          this.$emit('change', {
            ...this.schema.clean(this.model),
            isManual: this?.doc?.isManual,
            hasDeclaration: this?.doc?.hasDeclaration,
            locale: this.model.locale ?? this?.doc?.locale,
          });
          this.changeDirty = false;
        }, 500);
    },
    focusedField(fieldKey) {
      if (fieldKey) this.lastFocusedField = fieldKey;
      if (fieldKey === 'taxRate' && !this.model.taxRate) this.model.taxRate = 17;
    },
    paramDialogVisible(visible) {
      if (!visible) this.customSettingsSelected = false;
    },
    cleanedTemplates(templates) {
      if (this.updateParamsFlag) {
        this.model.structureParams = templates.length
          ? clone(templates[0].structureParams)
          : getDefaultParams(this.model.type);
        this.updateParamsFlag = false;
      }
    },
  },
  methods: {
    onTemplateChange(id) {
      if (!id) {
        this.customSettingsSelected = true;
        this.openStructureParams();
        this.$delete(this.model, 'templateId');
      } else {
        const clonedSelectedTemplate = clone(this.cleanedTemplates.find((template) => template.id === id));
        this.model.structureParams = clonedSelectedTemplate.structureParams;
        this.$set(this.model, 'locale', clonedSelectedTemplate.locale);
        this.$set(this.model, 'templateId', id);
      }
    },
    saveNewTemplate(params) {
      this.newTemplate = params;
      this.nameModalOpen = true;
    },
    async createNewTemplate({ name, isManual }) {
      await this.templateCreate({
        data: {
          name,
          isManual,
          supplierId: this.model.supplierId,
          documentType: this.model.type,
          fields: [],
          structureParams: this.newTemplate,
        },
      });
      await this.refetchTemplates();
      this.nameModalOpen = false;
      this.updateParamsFromDrawer(this.newTemplate);
    },
    async updateTemplate(params) {
      await this.templateUpdate({
        id: this.selectedTemplateId,
        data: {
          ...omit(['id', 'structureToken'], this.currentTemplate),
          structureParams: params,
        },
      });
      this.updateParamsFromDrawer(params);
    },
    fetchNameSuggestions(qs, cb) {
      const suggestions = this.supplierItems
        .filter((i) => !qs || i.name.toLowerCase().includes(qs.toLowerCase()))
        .map((i) => ({ value: i.name }));
      cb(suggestions);
    },
    getDocumentNumberValidationError() {
      if (this.inValidationProcess) {
        return null;
      }

      return this.replicateDocumentId ? 'error' : null;
    },
    getOrderReferenceValidationError() {
      if (this.inValidationProcess) {
        return null;
      }

      return this.existingDocumentIdByOrderReference ? 'error' : null;
    },
    getMarginEndAutoClassByDirection() {
      return this.$t('direction') === 'rtl' ? 'me-auto' : 'ms-auto';
    },
    handleDocumentNumberChange(documentNumber) {
      const documentNumberToRetrieve = documentNumber;

      setTimeout(async () => {
        const documentNumberAndSupplierIdExist = this.model.documentNumber && this.model.supplierId;
        const dateToSearch = this.model.issueDate ?? this.model.deliveryDate;
        if (documentNumberAndSupplierIdExist && this.model.documentNumber === documentNumberToRetrieve) {
          const from = dateToSearch && new Date(new Date(dateToSearch).getFullYear(), 0, 2).toISOString().split('T')[0];

          const to = dateToSearch && new Date(new Date(dateToSearch).getFullYear(), 12, 1).toISOString().split('T')[0];
          //TODO: fix query to search fromDate and toDate as delivery date when no issue date provided
          const {
            data: { documentsNew2 },
          } = await this.$apollo.query({
            query: DOCUMENTS_BY_DOCUMENT_NUMBER_QUERY,
            variables: {
              businessId: this.businessId,
              documentNumber: documentNumberToRetrieve,
              supplierId: this.model.supplierId,
              types: [this.model.type],
              fromDate: from,
              toDate: to,
            },
          });

          const documents = documentsNew2?.nodes || [];
          const documentsWithoutReplications = documents.filter((doc) => !doc.replicateOf);
          const documentsWithReplications = documents.filter((doc) => !!doc.replicateOf);
          this.existingDocumentIdByDocumentNumber =
            (documentsWithoutReplications[0] || documentsWithReplications[0])?.id ?? null;
        }
      }, 500);
    },
    updateParamsFromDrawer(params) {
      this.model.structureParams = params;
      this.paramDialogVisible = false;
    },
    updateDocParams() {
      const model = this.model;
      if (!(model.supplierId && model.type)) {
        model.structureParams = {};
        return;
      }
      this.updateParamsFlag = true;
    },
    onSupplierChange() {
      this.model.replicateOf = undefined;
      this.updateDocParams();
      this.resetItemsAndNotes();
      if (this.model.documentNumber) {
        this.handleDocumentNumberChange(this.model.documentNumber);
      }
    },
    onTypeChange() {
      this.model.replicateOf = undefined;
      this.$nextTick(() => this.updateDocParams());
      if (this.model.documentNumber) {
        this.handleDocumentNumberChange(this.model.documentNumber);
      }
    },
    onReplicateOfToggle() {
      this.model.replicateOf = this.model.replicateOf ? undefined : this.existingDocumentIdByDocumentNumber;
    },
    resetItems() {
      this.model.items = [];
    },
    resetReferences() {
      if (this.model.references) this.model.references = [];
    },
    resetItemsAndNotes() {
      if (this.model.items) this.model.items = [];
      if (this.model.references) this.model.references = [];
    },
    updateItemFields(fieldKey) {
      const keys = fieldKey.split('.');
      const field = keys.pop();
      const itemModel = keys.reduce((model, key) => model[key], this.model);

      const fieldMapping = { name: 'name', sku: 'sku' };

      const [source, target] = field === 'sku' ? ['sku', 'name'] : ['name', 'sku'];
      const value = itemModel[source];
      const item = value ? this.supplierItems.find((i) => i[fieldMapping[source]] === value) : undefined;

      if (item) {
        itemModel[target] = item[fieldMapping[target]] || '';
        itemModel.productId = item.id;
      } else if (itemModel.productId) {
        itemModel[target] = '';
        itemModel.productId = '';
      }
    },
    cleanDocumentNumber(documentNumber) {
      return documentNumber.replace(/ /g, '');
    },
    cleanOrderReference(orderReference) {
      return orderReference.replace(/ /g, '');
    },
    async insertDataToLastFocusedField(data) {
      const locale = this.currentLocale;
      if (!this.lastFocusedField) {
        this.$alert('Need to select input to insert data to it');
        return;
      }
      this.focusedField = this.lastFocusedField;
      if (!data.length) return;

      const fieldType = this.schema.getDefinition(this.lastFocusedField).type[0].type;
      const keys = this.lastFocusedField.split('.');
      const field = keys.pop();
      const parentModel = keys.reduce((model, key) => model[key], this.model);
      const parentKey = keys.pop();
      if (!parentKey || Number.isNaN(+parentKey)) {
        let value;
        if (fieldType === Number) {
          value = convertValue(data[0], 'Number');
        } else if (fieldType === SimpleSchema.Integer) {
          value = convertValue(data[0], 'Money');
        } else if (fieldType === Date) {
          value = convertValue(data[0], 'Date', locale);
        } else {
          value = data[0];
        }
        if (field === 'documentNumber') value = this.cleanDocumentNumber(value);
        if (field === 'orderReference') value = this.cleanOrderReference(value);

        parentModel[field] = value;
      } else {
        // parent is an array
        const arrayModel = keys.reduce((model, key) => model[key], this.model);
        const startIndex = +parentKey;
        data.forEach((text, index) => {
          const currentIndex = startIndex + index;
          if (!arrayModel[currentIndex]) {
            const arrayElemKey = keys.join('.').replace(/\.\d+(?=\.|$)/g, '.$') + '.$';
            arrayModel.push(getModel(this.schema.getDefinition(arrayElemKey).type[0].type));
          }
          let value;
          if (fieldType === Number && field !== 'price') {
            value = convertValue(text, 'Number');
          } else if (fieldType === SimpleSchema.Integer || field === 'price') {
            value = convertValue(text, 'Money');
          } else if (fieldType === Date) {
            value = convertValue(text, 'Date', locale);
          } else {
            value = text;
          }
          if (field === 'documentNumber') value = this.cleanDocumentNumber(value);
          if (field === 'orderReference') value = this.cleanOrderReference(value);

          arrayModel[currentIndex][field] = value;

          if (field === 'sku') {
            const item = this.supplierItems.find((i) => i.sku === value);
            if (item) {
              arrayModel[currentIndex].productId = item.id;
              arrayModel[currentIndex].name = item.name;
            } else {
              arrayModel[currentIndex].productId = '';
            }
          }

          if (field === 'name') {
            const item = this.supplierItems.find((i) => i.name === value);
            if (item) {
              arrayModel[currentIndex].productId = item.id;
              if (item.sku) arrayModel[currentIndex].sku = item.sku;
            } else {
              arrayModel[currentIndex].productId = '';
            }
          }
        });
      }
      await this.$nextTick();
      await this.$nextTick();

      const focusedInput = this.$refs.tabContainer.querySelector('input:focus');
      const allInputs = [...this.$refs.tabContainer.querySelectorAll('input')];
      const nextInput = allInputs[allInputs.indexOf(focusedInput) + 1];
      if (nextInput) nextInput.focus();
    },
    updateForm(data) {
      const convertedData = {};
      Object.keys(data).forEach((key) => {
        const fieldType = this.schema.getDefinition(key).type[0].type;
        if (fieldType === Array) {
          convertedData[key] = data[key].map((elemData) => {
            const convertedElemeData = {};
            Object.keys(elemData).forEach((elemKey) => {
              const genericKey = `${key}.$.${elemKey}`;
              const elemFieldType = this.schema.getDefinition(genericKey).type[0].type;
              convertedElemeData[elemKey] = convertValueFromField(elemData[elemKey], elemFieldType, elemKey);
            });
            return convertedElemeData;
          });
        } else {
          convertedData[key] = convertValueFromField(data[key], fieldType, key);
        }
      });
      if (convertedData.items) {
        convertedData.items.forEach((item) => {
          if (item.sku) {
            const foundItem = this.supplierItems.find((si) => si.code === item.sku);
            if (foundItem) {
              item.productId = foundItem.id;
              item.name = foundItem.name;
            }
          } else {
            const foundItem = this.supplierItems.find((si) => si.name === item.name);
            if (foundItem) {
              item.productId = foundItem.id;
              item.sku = foundItem.code;
            }
          }
        });
      }
      this.$refs.autoform.setData(convertedData);
    },
    getValidationRowErrors(index) {
      return this.rowErrors.find(({ path }) => path[1] === Number(index))?.context.invalidRelations;
    },
    isRowError(index) {
      const rowError = this.getValidationRowErrors(index);
      return !!rowError || !this.model.items[index].productId;
    },

    setInValidationProcess(status /* boolean */) {
      // This method use to overcome an issue with ui element that isn't show the errors after validating form manually.
      // source: https://stackoverflow.com/a/56617523
      this.inValidationProcess = status;
    },

    // being used via $ref, outside of the component - RecordDocuments
    async validateAndGetCleanedModel() {
      const currencyLocaleStyle = this.currencyLocaleStyle;

      const emptyRow = (row) => Object.keys(row).every((key) => isNil(row[key]) || isEmpty(row[key]));
      const lastItem = this.model.items?.[this.model.items?.length - 1];
      if (lastItem && emptyRow(lastItem)) this.model.items.pop();
      const lastCharge = this.model.generalCharges?.[this.model.generalCharges?.length - 1];
      if (lastCharge && emptyRow(lastCharge)) this.model.generalCharges.pop();
      const lastReference = this.model.references?.[this.model.references?.length - 1];
      if (lastReference && emptyRow(lastReference)) this.model.references.pop();
      await this.$nextTick();

      const form = this.$refs.autoform.$refs.form;
      const formContext = this.schema.namedContext();
      formContext.reset();

      this.setInValidationProcess(true);
      const valid = await form.validate().catch(() => false);
      this.setInValidationProcess(false);

      if (!valid) return;

      await this.$nextTick();
      const setErrors = (errors) => {
        const docErr = errors.find(({ type, path }) => type === 'custom.unbalanced' && !path.length);
        const itemErrors = errors.filter(({ type, path }) => type === 'custom.unbalanced' && path.length === 2);
        const documentValidationErrors = docErr ? transformDocumentValidationErrors(docErr) : [];
        const itemValidationErrors = flatten(itemErrors.map(transformItemValidationErrors));
        const validationErrors = [
          ...errors.map(mapValidationErrors.bind(null, currencyLocaleStyle)),
          ...documentValidationErrors,
          ...itemValidationErrors,
        ];

        formContext.addValidationErrors(validationErrors);

        this.setInValidationProcess(true);
        form.validate().catch(() => false);
        this.setInValidationProcess(false);
      };

      return { formData: this.schema.clean(this.model), setErrors };
    },

    remove() {
      this.$emit('remove');
    },
    handlePaste(event) {
      const textData = event.clipboardData.getData('text/plain');
      this.insertDataToLastFocusedField(textData.split('\n'));
    },
    insertRecommendation(fieldKey) {
      const recommendation = this.recommendations[fieldKey];
      const keys = fieldKey.split('.');
      const field = keys.pop();
      const parentModel = keys.reduce((model, key) => model[key], this.model);
      parentModel[field] = recommendation.recommendedValue;
      recommendation.chosen = 'recommended';
    },
    openStructureParams() {
      this.paramDialogVisible = true;
    },
    /**
     * returns true in case the document is manual and the given template is manual
     * in the rest of the cases it will return false
     */
    disableTemplatePick({ isManual: isTemplateManual }) {
      return !this.doc.isManual ? false : !isTemplateManual;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.manual-document-icon {
  margin: 0 5px;
}

.custom-disabled-class {
  color: rgb(210, 210, 210);
}

.direction {
  direction: initial;
}

.center-select-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: $typography-primary;
}
.dropdown-text-color :deep(.el-input__inner) {
  color: $typography-primary;
}

::v-deep .direction-ltr .el-input__inner {
  direction: ltr;
}
::v-deep .el-form-item__error {
  z-index: 1;
}

.business-name-container {
  max-width: 180px;
  min-width: 100px;
}

::v-deep .business-name-text {
  .text-truncate,
  .position-absolute {
    font-size: 20px;
    font-weight: 700;
  }
}
</style>
